<template>
<div id="orderManage">
  <!--头部搜索-->
  <div class="bg-white searchDom">
    <div class="flex align-center justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="emptyKeyword" v-model="listQuery.keyword" placeholder="请输入用户ID/微信昵称查询" suffix-icon="search"></el-input>
        <i style="cursor: pointer;" @click="searKeyword" class="el-icon-search"></i>
      </div>
      <el-button  style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;" @click="exportData">导出</el-button>
    </div>
  </div>
  <!--搜索列表-->
  <div class="searchList">
    <ul class="row margin-bottom-16 flex-start">
      <li v-for="(v,i) in dataList" :key="i">
			  <div class="fj_post">
          <img :src="v.user_face?v.user_face:'https://xcx.yihuichuang.com/yhc_dy/img/header_img.png'" alt="" style="width:96px;height：96px">
			  	<div class="details">
            <p class="flex justify-between">用户ID：<span>{{v.user_id}}</span></p>
			  		<p class="flex justify-between">微信昵称：<span>{{v.user_nickname}}</span></p>
			  		<p class="flex justify-between">注册时间：<span>{{v.user_create_time*1000|getDate}}</span></p>
			  		<p class="flex justify-between">
              <span>下单数量：{{v.order_count}}</span>
              <span>未使用优惠券：{{v.ucoupon_ava_count}}</span>
            </p>
            <p class="flex justify-between">
              <span>预估分佣：{{v.ready_ma_all_money}}</span>
              <span>已提现：{{v.user_income_money}}</span>
              <span @click="getUserAddress(v.user_openid)" style="color: #4E9F5B;cursor:pointer;margin-left:20px">查看地址</span>
            </p>
            <p class="flex justify-between">
              <span>间推人数：{{v.user_jk2_all_count}}</span>
              <el-button v-show="v.user_jk2_all_count!='0'" @click="showModel(v.user_id,'间推')" type="text" style="color: #4E9F5B !important;margin-top:-8px" size="medium">查看
              </el-button>
              <span>直推人数：{{v.user_jk1_all_count}}</span>
              <el-button v-show="v.user_jk1_all_count!='0'" @click="showModel(v.user_id,'直推')" type="text" style="color: #4E9F5B !important;margin-top:-8px" size="medium">查看
              </el-button>
            </p>
			  	</div>
			  </div>
		  </li> 
    </ul>
  </div>
  <!--分页-->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getTabList"
      :page.sync= "listQuery.page"
      :limit.sync="listQuery.limit"/>
  </div>
  <!-- 表格弹窗 -->
    <tableModel @closeTabModel='closeTabModel' @changeModelPag='changeModelPag' @changeModelPagSiz="changeModelPagSiz"
      :modelUserId='modelUserId' :showTabModel="showTabModel" :modelTitle="modelTitle"
      :modelTabInfo="modelTabInfo"></tableModel>
  <!-- 地址表格弹窗 -->
    <el-dialog id="addRess_modBox" title="用户地址" :visible.sync="address_model">
      <div class="">
        <el-table :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="addRess_data" stripe
          style="width: 100%">
          <el-table-column type="index" align="center" label="序号" width="60">
          </el-table-column>
          <el-table-column prop="addr_name" align="center" label="联系人">
          </el-table-column>
          <el-table-column prop="addr_phone" align="center" label="联系电话">
          </el-table-column>
          <el-table-column prop="addr_prov" align="center" label="省份">
          </el-table-column>
          <el-table-column prop="addr_city" align="center" label="城市">
          </el-table-column>
          <el-table-column prop="addr_dist" align="center" label="地区">
          </el-table-column>
          <el-table-column prop="addr_detail" align="center" label="街道" width="150">
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <Pagination v-show="addRess_page>1" :total="addRess_total" @change = "getUserAddress" 
        :page.sync="addRess_page"  :limit.sync="addRess_limit"/>
      </span>
    </el-dialog>
</div>
</template>
<script>
import {userList,userCenterAddr,userCenterJK} from '@/api/http'
import Pagination from '@/components/pagination'
import tableModel from './component/tableModel.vue'
export default {
    data(){
      return{
        dataList: [], //表格数据
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
        /* 弹窗参数*/
        showTabModel: false,
        modelTitle: '',
        modelUserId: 1,
        modelTabInfo: {},

        listQuery:{
          keyword:'',//查询条件
          page:1,//当前页面
          limit:6//条数
        },

        /* 点击直推、间推*/
        currentId: 1,
        currentJk: 1,
        tabMod_currentPag: 1,
        tabMod_currentPagSiz: 10,
        /* 地址model*/
        addRess_page: 1,
        addRess_limit: 10,
        addRess_total:1,
        addRess_pages:[10,20,30,40],
        address_model:false,
        addRess_data:[]
      }
    },
    created(){
      this.getTabList()//用户列表数据
    },
    filters:{
     allMoney(val) {
        let all_money = Number(val.ready_ma_all_money) + Number(val.ready_jk1_all_money) + Number(val.ready_jk2_all_money)
        return all_money.toFixed('2')
      },
    },
    methods:{
      //获取用户列表
      getTabList(){
        userList(this.listQuery).then(res=>{
          console.log('用户列表：'+res.data.data)
          let {list,page,count,limit}=res.data.data
          // console.log(res.data.code,list,page,count,limit)
          // if(res.data.code === '1'){
          //   this.rows = count
          //   this.dataList = list
          // }
          if(res.data.code == '1' && list){
            this.rows = count
            this.dataList = list
          }else if(res.data.code=='1'&&res.data.data.csv_file){
            window.open(res.data.data.csv_file)
            this.listQuery.limit=this.rows
          }
        })
      },
      //搜索
      searKeyword(){
        this.getTabList()
      },
      /* 清空输入框 */
      emptyKeyword(e){
        if(e.length=='0'){
          this.listQuery.page=1
          this.getTabList()
        }
      },
      //导出
      exportData(){
        this.listQuery.page=0
        this.listQuery.limit=9999
        this.getTabList()
      },
      //用户中心地址
      getUserAddress(user_openid){
        console.log(user_openid)
        this.address_model = true
        userCenterAddr({
          user_openid:user_openid,
          page:this.addRess_page,
          limit:this.addRess_limit
        }).then(res=>{
          console.log('收货地址：'+res.data.data.list)
          let {list} = res.data.data
          if(res.data.code == '1'){
            this.addRess_data = list
          }
        })
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      //直推，间推
      showModel(id,title){
        console.log(id,title)
        if(title == '间推'){
          this.currentJk = 2
        }else{
          this.currentJk = 1
        }
        this.tabMod_currentPag = 1,
        this.tabMod_currentPagSiz = 10
        this.modelTitle = title
        this.currentId = Number(id)
        this.getUserJk()
        this.showTabModel = true

      },

      //获取用户推荐列表jk 1-直推 2-间推
      getUserJk(){
        userCenterJK({
          user_id: this.currentId,
          jk: this.currentJk,
          page: this.tabMod_currentPag,
          limit: this.tabMod_currentPagSiz
        }).then(res=>{
          console.log(this.modelTitle,res.data)
          let {code,data} = res.data
          if(code == '1'){
            this.modelTabInfo = data
          } 
        })
      },
      // 关闭弹窗
      closeTabModel(e) {
        this.showTabModel = false
      },
      changeModelPag(page) {
        this.tabMod_currentPag = page
        this.getUserJk()
      },
      changeModelPagSiz(pagSiz) {
        this.tabMod_currentPagSiz = pagSiz
        this.getUserJk()
      },
    },
    components:{
      Pagination,
      tableModel
    }
}
</script>
<style>
    #orderManage{
  width: 100%;
}
#orderManage .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
} 
#orderManage .searchDom{
    height: 70px;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 24px 80px 4px 24px;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 60px;
    right: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
}
#orderManage .searchList{
  padding: 16px;
  margin-top: 76px;
}  
#orderManage ul{
  list-style: none;
  margin-left: -16px;
  margin-right: 16px;
}
#orderManage .searchList li{
  width: 32%;
  margin-right: 15px;
}
#orderManage ul li .order_number{
  border-bottom: 1px solid #ebedf0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#orderManage ul li .order h1 {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: #323233;
  display: block;
  margin-top: 2px;
}
#orderManage ul li .order font {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  display: block;
  float: right;
}
.order #order_state{
  margin-top: -15px;
}
.order span{
    display: block;
    white-space: nowrap;
    color: #323233;
    font-size: 14px;
    font-weight: bold;
}
.order em{
  display: block;
  white-space: nowrap;
  color: #323233;
  font-size: 14px;
}
.order p {
    color: #323233;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.order .order_kd{
  padding: 10px 14px;
  background: rgba(235,237,240,.3);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 20px;
}
.order .order_kd img{
  display: block;
  width: 80px;
  height: 36px;
  object-fit: cover;
  background: #ebedf0;
}
.order .order_kd i{
  display: block;
  width: 1px;
  height: 17px;
  background: #ebedf0;
  margin: 0 16px;
}
.order .order_kd .copy{
  display: block;
  white-space: nowrap;
  color: #79838b;
  font-size: 14px;
}
.pageNum {
    height: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: fixed;
    z-index: 20;
    left: 144px;
    right: 28px;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #000;
    font-size: 14px;
}
.fj_post {
    background-color: #ffffff;
    border: 1px solid #f6f6f6;
    border-left: 4px solid transparent;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 30px;
    /**height: 350px;**/
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
}
.fj_post .details{
  margin-top: 45px;
  margin-left: 15px;
}
.fj_post .thumb {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    float: left;
    margin-right: 30px;
    text-align: center;
}

.fj_post .details {
    flex: 1;
}
.fj_post .details p {
    margin-bottom: 10px;
    color: #6d6d6d !important;
    font-size: 14px;
}

.fj_post .details span{margin-right: 5px;}
.fj_post:hover {
    border: 1px solid #345e3b;
    border-left: 4px solid #345e3b;
}

</style>