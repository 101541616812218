<template id="modelTab">
<el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="modelTitle" id="allModel"
    :visible.sync="showTabModel" width="30%">
    <!-- 标题 -->
    <div class="model_title flex justify-between align-center">
      <div class="flex align-center">
           <span class="text-16 text-bold text-black">{{modelTitle}}</span> 
      </div>
      <i @click="closeTabModel" class="el-icon-close" style="cursor:pointer"></i>
    </div>
    <!-- 表格内容 -->
    <div class="margin-tb-24">
        <el-table :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="modelTabInfo.list" stripe height='400'
        style="width: 100%">
        <el-table-column prop="user_id" align="center" label="ID">
        </el-table-column>
        <el-table-column prop="user_face" align="center" label="头像">
            <template slot-scope="scope">
              <img style="width:48px;height:48px;border-radius:50%" :src="scope.row.user_face?scope.row.user_face:'https://xcx.yihuichuang.com/yhc_dy/img/header_img.png'" alt="">
            </template>
          </el-table-column>
        <el-table-column prop="user_nickname" align="center" label="微信昵称" width="180">
        </el-table-column>
        <el-table-column prop="user_create_time" align="center" label="注册时间">
                      <template slot-scope="scope">
              <span>{{scope.row.user_create_time*1000 | getDate }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="TA的下级">
          <template slot-scope="scope">
            <span>{{scope.row | allPeoCount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" align="center" label="下单数">
        </el-table-column>
        <el-table-column prop="address" align="center" label="预估收益">
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
</el-dialog>
</template>
<script>
export default {
    name:'table-model',
    data() {
      return {
        pageSizes: [10,20,30]
      };
    },
    filters:{
      allPeoCount(val){
        console.log('1',val)
        let allCount = Number(val.user_jk1_all_count)+Number(val.user_jk2_all_count)
        return allCount
      }
    },
    props:{
        showTabModel: Boolean,
        modelTitle: String,
        modelTabInfo:Object,
        modelUserId:Number
    },
    created(){
        console.log(this.tableList)
    },
    methods: {
      closeTabModel(){
          this.$emit('closeTabModel')
      }
    }
}
</script>
<style>
#allModel .el-dialog {
    border-radius: 8px;
    width: 780px !important;
    max-height: 600px;
    overflow-y: scroll;
  }
  #modelTab ::-webkit-scrollbar {
  /*隐藏滚轮*/
  /* display: none; */
  }

  #allModel .el-dialog__header {
    display: none !important;
  }

  #allModel .el-dialog__body {
    padding: 24px !important;
  }    
</style>